import React from 'react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ axis, data, colors, labels = ['1'], scalesRemove }) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },

    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      ...scalesRemove,
    },
  }

  const set = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data,
        backgroundColor: colors,
        stack: 'Stack 0',
      },
    ],
  }
  return (
    <div>
      <Bar options={{ ...options, indexAxis: axis }} data={set} />
    </div>
  )
}

export default BarChart
