const routeHandler = async (navigate, location, user) => {
  const paramRegEx = /.*\?((.*=.*)(&?))+/
  const routes = [
    'login',
    'dashboard',
    'reports',
    'register',
    'reset',
    'reset-password',
    'authenticate',
    'sink',
  ]
  const correctRoute = routes.includes(window.location.href.split('/')[3])

  if (
    (paramRegEx.test(window.location.href) &&
      window.location.href.includes('reset-password')) ||
    (paramRegEx.test(window.location.href) &&
      window.location.href.includes('authenticate'))
  )
    return
  if (!correctRoute) {
    if (user !== null) {
      navigate('/dashboard')
    }
    navigate('/login')
  }
  if (location.pathname.split('/').includes('dashboard') && user === null)
    navigate('/login')
  if (/(\/dashboard)+$/g.exec(window.location.href)) {
    if (user === null) navigate('/login')
    if (user.organisations.length < 1) {
      navigate('/login')
    }
  }
}

export default routeHandler
