import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../../config/validation'
import {
  deleteAppUser,
  editAppUser,
  resetAppUser,
} from '../../../../handlers/requests/users/appUsersHandler'
import ConfirmationModal from '../../../../components/dialogs/ConfirmationModal'
import { AlertContext } from '../../../../context/AlertContext'
import TextField from '../../../../ui-library/components/TextField'
import Button from '../../../../ui-library/components/Button'
import Link from '../../../../ui-library/components/Link'
import { FaLink } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../../components/shared/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import toCapitalised from '../../../../utils/capitaliseString'

function EditAppUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const initialEdit = {
    firstname: state?.user?.firstname || '',
    lastname: state?.user?.lastname || '',
    emailAddress: state?.user?.email || '',
    mobile: state?.user?.mobile || '',
  }
  const [selectedOrgs] = useState([])

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialEdit,
      validationSchema: addUserSchema,
      onSubmit: async () => await handleEdit(),
    })
  const handleEdit = async () => {
    await editAppUser(
      state?.user?.id,
      { values, selectedOrgs },
      setAlert,
      setLoading
    )
    navigate('/dashboard/system')
  }
  return (
    <>
      <DashboardContainer>
        <TabHeader
          title={'Edit'}
          username={
            state?.user?.firstname !== null &&
            state?.user?.firstname !== undefined &&
            state?.user?.lastname !== null &&
            state?.user?.lastname !== undefined &&
            `${toCapitalised(state?.user?.firstname)} ${toCapitalised(
              state?.user?.lastname
            )}`
          }
        >
          <Paper style={theme?.dashboard?.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/system')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h2'}>User Details</Typography>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    onClick={() => {
                      setConfirmReset(true)
                    }}
                    icon={FaLink}
                    icontype={'endIcon'}
                    source={'altButton'}
                    style={{
                      icon: {
                        color: '#FF5A28',
                        marginLeft: 30,
                      },
                      '&:hover': {
                        color: 'white',
                        backgroundColor: '#4B4D52',
                      },
                    }}
                  >
                    Reset Password
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmDelete(true)
                    }}
                    icon={MdDeleteForever}
                    icontype={'endIcon'}
                    iconSize={30}
                    style={{
                      icon: {
                        marginLeft: 40,
                      },
                    }}
                  >
                    Delete User
                  </Button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginTop: 30,
                      width: '150%',
                    }}
                  >
                    <TextField
                      type="firstname"
                      name="firstname"
                      title="First Name"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'firstname'}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="lastname"
                      name="lastname"
                      title="Last Name"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'lastname'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginTop: 20,
                      width: '150%',
                    }}
                  >
                    <TextField
                      type="emailAddress"
                      name="emailAddress"
                      title="Email"
                      placeholder="Email"
                      value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`emailAddress`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="mobile"
                      name="mobile"
                      title="Mobile"
                      placeholder="Mobile Number"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`mobile`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <Button
                    type="submit"
                    // icon={SaveIcon}
                    // icontype={'endIcon'}
                  >
                    Save
                  </Button>
                </form>
                <ConfirmationModal
                  onAccept={async () => {
                    await deleteAppUser(state?.user?.id, setAlert)
                    navigate('/dashboard/system')
                  }}
                  isOpen={confirmDelete}
                  onRequestClose={() => setConfirmDelete(false)}
                  type={'Delete'}
                  text={'Are you sure you wish to delete this user?'}
                  icon={MdDeleteForever}
                />
                <ConfirmationModal
                  onAccept={async () => {
                    await resetAppUser(state?.user?.email, setAlert)
                    setConfirmReset(false)
                  }}
                  isOpen={confirmReset}
                  onRequestClose={() => setConfirmReset(false)}
                  type={'Reset'}
                  text={'Are you sure you wish to reset this users password?'}
                  icon={FaLink}
                />
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default EditAppUser
