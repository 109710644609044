import React, { useContext } from 'react'
import Paper from '@mui/material/Paper'
import Typography from './Typography'
import SearchField from './SearchField'
import { ThemeContext } from './ThemeContext'
import BreadCrumbs from './BreadCrumbs'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const TabHeader = (props) => {
  const { title, username, children, showSearch, onChangeSearch, searchValue } =
    props
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <Paper
        style={{
          ...theme?.tabHeader,
          ...theme?.tabHeader?.[''],
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BreadCrumbs
            username={username}
            separator={<ChevronRightIcon style={{ color: 'white' }} />}
          />
          <Typography source="tabTitleHeader">
            {title}
            {username ? `: ${username}` : ''}
          </Typography>
        </div>
        {showSearch ? (
          <SearchField
            onChange={onChangeSearch}
            value={searchValue}
            placeholder={'Search'}
            position={'start'}
          />
        ) : (
          <></>
        )}
      </Paper>
      {children}
    </>
  )
}

export default TabHeader
