import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '../../../../ui-library/components/Button'
import { getAppUsers } from '../../../../handlers/requests/users/appUsersHandler'
import { OrganisationContext } from '../../../../context/OrganisationContext'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../ui-library/components/DataTable'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import LoadingAnim from '../../../../ui-library/components/loadingAnim/LoadingAnim'
import useDebounce from '../../../../hooks/useDebounce'
import moment from 'moment'
import uuid from 'react-uuid'
import Typography from '../../../../ui-library/components/Typography'
import IconButton from '../../../../ui-library/components/IconButton'
import { FaEdit, FaLink } from 'react-icons/fa'
import DashboardContainer from '../../../../components/shared/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useNavigate } from 'react-router-dom'
import { IoDocumentText } from 'react-icons/io5'
import { ImPlus } from 'react-icons/im'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

function AppUsersList(props) {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const debouncedSearch = useDebounce(search, 1000)
  const navigate = useNavigate()
  const [sortOrder, setSortOrder] = useState('firstname,ASC')

  useEffect(() => {
    const appUsers = async () => {
      setLoading(true)
      if (debouncedSearch) {
        const users = await getAppUsers(search, sortOrder)
        setUserData(users?.data)
        setLoading(false)
      } else {
        const users = await getAppUsers('', sortOrder)
        setUserData(users?.data)
        setLoading(false)
      }
    }
    appUsers()
  }, [currentOrg, debouncedSearch, sortOrder])

  return (
    <DashboardContainer>
      <TabHeader
        title={'System Users'}
        showSearch
        searchValue={search}
        onChangeSearch={(e) => setSearch(e.target.value)}
      >
        <Paper
          style={{
            ...theme?.dashboard?.container,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Button
              icon={ImPlus}
              icontype={'endIcon'}
              onClick={() => {
                navigate('/dashboard/system/add', {
                  state: { title: 'Add System User' },
                })
              }}
            >
              Add User
            </Button>
            <Button
              icon={IoDocumentText}
              icontype={'endIcon'}
              onClick={() => {
                navigate('/dashboard/system/gdpr')
              }}
              source={'altButton'}
              style={{
                icon: {
                  color: '#FF5A28',
                },
                '&:hover': {
                  color: 'white',
                  backgroundColor: '#4B4D52',
                },
              }}
            >
              GDPR Centre
            </Button>
          </div>
          <>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  <LoadingAnim />
                </div>
              ) : userData?.length > 0 ? (
                <TableContainer {...props} theme={theme}>
                  <Table {...props} theme={theme}>
                    <TableHead {...props} theme={theme}>
                      <TableCell {...props} theme={theme}>
                        <Typography
                          style={{
                            display: 'inline-block',
                            'vertical-align': 'middle',
                            'user-select': 'none',
                          }}
                        >
                          Name
                        </Typography>
                        <div
                          style={{
                            display: 'inline-block',
                            'vertical-align': 'middle',
                            marginTop: 7,
                          }}
                          onClick={() => {
                            if (sortOrder === 'firstname,DESC')
                              setSortOrder('firstname,ASC')
                            else {
                              setSortOrder('firstname,DESC')
                            }
                          }}
                        >
                          {sortOrder === 'firstname,ASC' ||
                          sortOrder === 'lastLoggedIn,ASC' ||
                          sortOrder === 'lastLoggedIn,DESC' ? (
                            <BiChevronDown
                              style={{
                                width: 25,
                                height: 25,
                                color: '#FF5A28',
                              }}
                            />
                          ) : (
                            <BiChevronUp
                              style={{
                                width: 25,
                                height: 25,
                                color: '#FF5A28',
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell {...props} theme={theme}>
                        <Typography
                          style={{
                            display: 'inline-block',
                            'vertical-align': 'middle',
                            'user-select': 'none',
                          }}
                        >
                          Last Login
                        </Typography>
                        <div
                          style={{
                            display: 'inline-block',
                            'vertical-align': 'middle',
                            marginTop: 7,
                          }}
                          onClick={() => {
                            if (sortOrder === 'lastLoggedIn,ASC')
                              setSortOrder('lastLoggedIn,DESC')
                            else setSortOrder('lastLoggedIn,ASC')
                          }}
                        >
                          {sortOrder === 'lastLoggedIn,DESC' ||
                          sortOrder === 'firstname,DESC' ||
                          sortOrder === 'firstname,ASC' ? (
                            <BiChevronDown
                              style={{
                                width: 25,
                                height: 25,
                                color: '#FF5A28',
                              }}
                            />
                          ) : (
                            <BiChevronUp
                              style={{
                                width: 25,
                                height: 25,
                                color: '#FF5A28',
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell {...props} theme={theme} align={'right'}>
                        <Typography
                          style={{
                            display: 'inline-block',
                            'vertical-align': 'middle',
                            'user-select': 'none',
                            float: 'right',
                          }}
                        >
                          Action
                        </Typography>
                      </TableCell>
                    </TableHead>
                    {userData?.map((user, index) => {
                      const firstname =
                        user?.firstname !== null ? user?.firstname : ''
                      const lastname =
                        user?.lastname !== null ? user?.lastname : ''
                      const lastLoggedIn = moment(user?.lastLoggedIn).fromNow(
                        true
                      )
                      const notInvalidLastLogin =
                        lastLoggedIn === 'Invalid date' ? 'N/A' : lastLoggedIn
                      return (
                        <TableRow theme={theme} {...props} key={uuid()}>
                          <TableCell theme={theme} {...props} align="left">
                            <Typography>{`${firstname} ${lastname}`}</Typography>
                          </TableCell>

                          <TableCell theme={theme} {...props}>
                            <Typography>{notInvalidLastLogin}</Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <IconButton
                              source={'tableIcon'}
                              style={{ float: 'right' }}
                              icon={FaEdit}
                              onClick={() => {
                                navigate('/dashboard/system/edit', {
                                  state: { user },
                                })
                              }}
                            />
                            <IconButton
                              source={'tableIcon'}
                              style={{ float: 'right', color: 'red' }}
                              icon={FaLink}
                              onClick={() => {
                                navigate('/dashboard/system/edit', {
                                  state: { user, title: 'Edit System User' },
                                })
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  source={'h1'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  No Data
                </Typography>
              )}
            </Stack>
          </>
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default AppUsersList
