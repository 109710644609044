import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../../config/validation'
import { addAdminUserHandler } from '../../../../handlers/requests/users/appUsersHandler'
import { AlertContext } from '../../../../context/AlertContext'
import TextField from '../../../../ui-library/components/TextField'
import Button from '../../../../ui-library/components/Button'
import Link from '../../../../ui-library/components/Link'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../../components/shared/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'

function AddAppUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [selectedOrgs] = useState([])
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        firstname: '',
        lastname: '',
        emailAddress: '',
        mobile: '',
      },
      validationSchema: addUserSchema,
      onSubmit: () => handleAdd(),
    })

  const handleAdd = async () => {
    await addAdminUserHandler(
      { values, selectedOrgs, type: 'admin' },
      setAlert,
      setLoading
    )
    navigate('/dashboard/system')
  }
  return (
    <>
      <DashboardContainer>
        <TabHeader title={state?.title}>
          <Paper style={theme?.dashboard?.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/system')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h1'}>User Details</Typography>

                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginTop: 30,
                      width: '150%',
                    }}
                  >
                    <TextField
                      type="firstname"
                      name="firstname"
                      title="First Name"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'firstname'}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="lastname"
                      name="lastname"
                      title="Last Name"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'lastname'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginTop: 20,
                      width: '150%',
                    }}
                  >
                    <TextField
                      type="emailAddress"
                      name="emailAddress"
                      title="Email"
                      placeholder="Email"
                      value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`emailAddress`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="mobile"
                      name="mobile"
                      title="Mobile"
                      placeholder="Mobile Number"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`mobile`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <Button
                    type="submit"
                    // icontype={'endIcon'}
                    // icon={SaveIcon}
                  >
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default AddAppUser
