import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import style from '../../style/style'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AlertContext } from '../../context/AlertContext'
import LoadingButton from '../../ui-library/components/LoadingButton'
import Logo from '../../ui-library/components/Logo'
import Typography from '../../ui-library/components/Typography'
import Link from '../../ui-library/components/Link'
import TextField from '../../ui-library/components/TextField'
import { authenticate2fa } from '../../handlers/requests/auth/loginHandler'
import { UserContext } from '../../context/UserContext'
import { OrganisationContext } from '../../context/OrganisationContext'
import uiText from '../../resources/uiText'

export default function Auth2FA() {
  const navigate = useNavigate()
  const signupData = uiText.authenticate
  const { setUser } = useContext(UserContext)
  const { setAlert } = useContext(AlertContext)
  const { setOrganisations } = useContext(OrganisationContext)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()
  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: 'white',
            position: 'absolute',
            margin: 'auto',
            zIndex: 1,
          }}
        />
      ) : (
        <Formik
          // validationSchema={passwordSchema}
          initialValues={{
            authCode: '',
          }}
          onSubmit={async (values) => {
            await authenticate2fa(
              values.authCode,
              token || state?.twoFAToken,
              navigate,
              setAlert,
              setLoading,
              setUser,
              setOrganisations
            )
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div style={style.auth.container}>
                <div style={style.auth.layout}>
                  <Logo image={''} alt="Logo" />
                  <Typography
                    source={'subtitle'}
                    style={{
                      marginBottom: 60,
                      textAlign: 'center',
                      lineHeight: 0.8,
                      fontSize: 40,
                    }}
                  >
                    {signupData.subTitle}
                  </Typography>

                  <TextField
                    type="authCode"
                    name="authCode"
                    placeholder={signupData.code_label}
                    value={values.authCode}
                    onChange={handleChange}
                    style={{ marginBottom: 5, marginTop: 5 }}
                    source={'default'}
                  />
                  <Card
                    className="error"
                    sx={
                      errors?.authCode && touched?.authCode && errors?.authCode
                        ? style.error
                        : {}
                    }
                  >
                    {errors?.authCode && touched?.authCode && errors?.authCode}
                  </Card>
                  <LoadingButton
                    // icon={IoIosArrowDropright}
                    // icontype={'endIcon'}
                    type={'submit'}
                    source={'authButton'}
                  >
                    {signupData.submit_label}
                  </LoadingButton>
                  <Link source={'link'} onClick={() => navigate('/login')}>
                    {signupData.link_label}
                  </Link>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Grid>
  )
}
