import React from 'react'
import { Route, Routes } from 'react-router-dom'
import theme from '../../style/theme'
import Login from '../../screens/auth/Login'
import Register from '../../screens/auth/Register'
import Reset from '../../screens/auth/Reset'
import NotFound from '../../screens/NotFound'
import Password from '../../screens/auth/Password'
import KitchenSink from '../../ui-library/KitchenSink'
import Auth2FA from '../../screens/auth/Auth2FA'
import Gdpr from '../../screens/dashboard/users/system/GDPR'
import AddAppUser from '../../screens/dashboard/users/system/AddUser'
import EditAppUser from '../../screens/dashboard/users/system/EditUser'
import AppUsersList from '../../screens/dashboard/users/system/UsersList'
import UpcomingChallenges from '../../screens/dashboard/challenges/UpcomingChallenges'
import PreviousChallenges from '../../screens/dashboard/challenges/PreviousChallenges'
import CreateChallenge from '../../screens/dashboard/challenges/CreateChallenge'
import EditChallenge from '../../screens/dashboard/challenges/EditChallenge'
import Results from '../../screens/dashboard/Results'
import Statistics from '../../screens/dashboard/kpi/Statistics'

function Router() {
  return (
    <Routes>
      {/*  AUTH ROUTES */}
      <Route path="/login" element={<Login spacing={theme.spacing} />} />
      <Route path="/register" element={<Register spacing={theme.spacing} />} />
      <Route path="/reset" element={<Reset spacing={theme.spacing} />} />
      <Route path="/authenticate" element={<Auth2FA />} />
      <Route path="/reset-password" element={<Password />} />
      {/* DASHBOARD ROUTES */}
      <Route path="/dashboard" element={<UpcomingChallenges />} />
      {/* <Route path="/dashboard/system" element={<UsersList />} /> */}
      {/* <Route path="/dashboard/system/add" element={<AddUser />} /> */}
      {/* <Route path="/dashboard/system/edit" element={<EditUser />} /> */}
      <Route path="/dashboard/system" element={<AppUsersList />} />
      <Route path="/dashboard/system/add" element={<AddAppUser />} />
      <Route path="/dashboard/system/edit" element={<EditAppUser />} />
      <Route path="/dashboard/system/gdpr" element={<Gdpr />} />

      <Route
        path="/dashboard/challenges/create"
        element={<CreateChallenge />}
      />
      <Route path="/dashboard/challenges/edit" element={<EditChallenge />} />

      {/*  challenges */}
      <Route
        path="/dashboard/upcoming-challenges"
        element={<UpcomingChallenges />}
      />
      <Route
        path="/dashboard/previous-challenges"
        element={<PreviousChallenges />}
      />
      <Route path="/dashboard/results" element={<Results />} />
      <Route path="/dashboard/stats" element={<Statistics />} />
      {/* <Route path="/dashboard/videos-archive" element={<VideosArchive />} /> */}
      {/* MISC */}
      <Route path="/sink" element={<KitchenSink />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Router
