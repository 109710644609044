import { deleteGDPR, getGDPR } from '@appt-digital/frontend-api-lib-wodity'

const getGDPRData = async (id, username) => {
  const res = await getGDPR({}, id)
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${username}.xlsx`) // or any other extension
  document.body.appendChild(link)
  link.click()
}

const deleteGDPRData = async (id, setAlert) => {
  const res = await deleteGDPR({}, id)
  if (res.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting user data',
      action: false,
    })
  } else {
    setAlert({
      open: true,
      severity: 'success',
      message: 'User data deleted successfully!',
      action: false,
    })
  }
}

export { getGDPRData, deleteGDPRData }
