import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { OrganisationContext } from '../../../context/OrganisationContext'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../ui-library/components/DataTable'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import LoadingAnim from '../../../ui-library/components/loadingAnim/LoadingAnim'
import useDebounce from '../../../hooks/useDebounce'
import uuid from 'react-uuid'
import Typography from '../../../ui-library/components/Typography'
import IconButton from '../../../ui-library/components/IconButton'
import { FaEdit } from 'react-icons/fa'
import DashboardContainer from '../../../components/shared/DashboardContainer'
import TabHeader from '../../../ui-library/components/TabHeader'
import { getAllChallenges } from '../../../handlers/requests/challenges/challengeHandler'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { MdDeleteForever } from 'react-icons/md'
import { deleteChallenge } from '@appt-digital/frontend-api-lib-wodity'

function PreviousChallenges(props) {
  const [challengeData, setChallengeData] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [deleted, setDeleted] = useState(false)
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const debouncedSearch = useDebounce(search, 1000)
  const navigate = useNavigate()

  useEffect(() => {
    const allChallenges = async () => {
      setLoading(true)
      if (debouncedSearch) {
        const challenges = await getAllChallenges(search)
        const previous = challenges?.data?.data.filter((challenge) => {
          return new Date(challenge.end) < new Date()
        })
        setChallengeData(previous)
        setLoading(false)
      } else {
        const challenges = await getAllChallenges('')
        const previous = challenges?.data?.data.filter((challenge) => {
          return new Date(challenge.end) < new Date()
        })
        setChallengeData(previous)
        setLoading(false)
      }
    }
    allChallenges()
  }, [currentOrg, debouncedSearch, deleted])

  return (
    <DashboardContainer>
      <TabHeader
        title={'Previous Challenges'}
        showSearch
        searchValue={search}
        onChangeSearch={(e) => setSearch(e.target.value)}
      >
        <Paper
          style={{
            ...theme?.dashboard?.container,
          }}
        >
          {/* <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}> */}
          {/*  <Button */}
          {/*    source={'altButton'} */}
          {/*    icon={FaUserPlus} */}
          {/*    icontype={'endIcon'} */}
          {/*    onClick={() => { */}
          {/*      navigate('/dashboard/system/add', { */}
          {/*        state: { title: 'Add App User' }, */}
          {/*      }) */}
          {/*    }} */}
          {/*  > */}
          {/*    Add User */}
          {/*  </Button> */}
          {/*  <Button */}
          {/*    icon={FaDatabase} */}
          {/*    icontype={'endIcon'} */}
          {/*    onClick={() => { */}
          {/*      navigate('/dashboard/system/gdpr') */}
          {/*    }} */}
          {/*  > */}
          {/*    GDPR Centre */}
          {/*  </Button> */}
          {/* </div> */}
          <>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  <LoadingAnim />
                </div>
              ) : challengeData?.length > 0 ? (
                <TableContainer {...props} theme={theme}>
                  <Table {...props} theme={theme}>
                    <TableHead {...props} theme={theme}>
                      {['From', 'To', 'Excercise', 'Action']?.map(
                        (header, index) => {
                          return (
                            <TableCell {...props} key={index} theme={theme}>
                              <Typography
                                style={
                                  header === 'Action'
                                    ? {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginRight: 20,
                                      }
                                    : {}
                                }
                              >
                                {header}
                              </Typography>
                            </TableCell>
                          )
                        }
                      )}
                    </TableHead>
                    {challengeData?.map((challenge, index) => {
                      return (
                        <TableRow theme={theme} {...props} key={uuid()}>
                          <TableCell theme={theme} {...props} align="left">
                            <Typography>
                              {moment(challenge?.start).format() ===
                              'Invalid date'
                                ? 'N/A'
                                : moment(challenge?.start).format('ddd MMM Do')}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>
                              {moment(challenge?.end).format() ===
                              'Invalid date'
                                ? 'N/A'
                                : moment(challenge?.end).format('ddd MMM Do')}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>{challenge?.name}</Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <IconButton
                              source={'tableIcon'}
                              style={{ float: 'right' }}
                              icon={FaEdit}
                              onClick={() => {
                                navigate('/dashboard/challenges/edit', {
                                  state: {
                                    challenge,
                                    title: 'Edit Challenge',
                                    prevRoute: '/dashboard/previous-challenges',
                                  },
                                })
                              }}
                            />
                            <IconButton
                              source={'tableIcon'}
                              style={{
                                float: 'right',
                                icon: {
                                  width: 27,
                                  height: 27,
                                },
                              }}
                              icon={MdDeleteForever}
                              onClick={async () => {
                                await deleteChallenge(challenge?.id)
                                setDeleted(!deleted)
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  source={'h1'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  No Data
                </Typography>
              )}
            </Stack>
          </>
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default PreviousChallenges
