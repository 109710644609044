import * as Yup from 'yup'
import { number } from 'yup'
import uiText from '../resources/uiText'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
})

const activateSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
  terms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
})

const resetSchema = Yup.object().shape({
  email: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
})
const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
})
const editClientSchema = Yup.object().shape({
  name: Yup.string()
    .required(uiText.errors.projectName.required)
    .min(2, uiText.errors.projectName.invalid),
  projectNumber: Yup.number()
    .typeError('Project Number must be a number')
    .required(uiText.errors.projectNumber.required)
    .min(1, uiText.errors.projectNumber.invalid),
  projectLocation: Yup.string()
    .required(uiText.errors.projectLocation.required)
    .min(2, uiText.errors.projectLocation.invalid),
  projectStartDate: Yup.date().required(uiText.errors.projectStartDate),
  projectEndDate: Yup.date()
    .required(uiText.errors.projectStartDate)
    .min(Yup.ref('projectStartDate'), `End date can't be before start date`)
    .required(uiText.errors.projectEndDate),
  projectPhase: Yup.string()
    .required(uiText.errors.projectPhase.required)
    .min(1, uiText.errors.projectPhase.invalid),

  LTIRMin: number().moreThan(0).min(0, uiText.errors.LTIRMin.invalid),
  LTIRMax: number().moreThan(0).min(1, uiText.errors.LTIRMax.invalid),
  TRIRMin: number().moreThan(0).min(0, uiText.errors.TRIRMin.invalid),
  TRIRMax: number().moreThan(0).min(1, uiText.errors.TRIRMax.invalid),
  DARTMin: number().moreThan(0).min(0, uiText.errors.DARTMin.invalid),
  DARTMax: number().moreThan(0).min(1, uiText.errors.DARTMax.invalid),
})

const addUserSchema = Yup.object().shape({
  firstname: Yup.string()
    .required(uiText.errors.firstName.required)
    .min(2, uiText.errors.firstName.invalid),
  lastname: Yup.string()
    .required(uiText.errors.lastName.required)
    .min(2, uiText.errors.lastName.invalid),
  emailAddress: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  mobile: Yup.string()
    .min(11, uiText.errors.mobile.invalid)
    .required(uiText.errors.mobile.required),
})
const URLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
const createChallengeSchema = Yup.object().shape({
  name: Yup.string()
    .required('Challenge name is required')
    .min(1, 'Challenge name is too short'),
  start: Yup.date(),
  end: Yup.date().min(Yup.ref('start'), `End date can't be before start date`),
  instructionalVideoUrl: Yup.string().matches(URLRegex, 'URL is invalid'),
  // recordType: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // moreInfo: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // prize: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // rulesMaleRX: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // rulesMaleScaled: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // rulesFemaleRX: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
  // rulesFemaleScaled: Yup.string()
  //   .required(uiText.errors.lastName.required)
  //   .min(2, uiText.errors.lastName.invalid),
})
const kpiSchema = Yup.object().shape({
  type: Yup.string().min(1),
  from: Yup.date(),
  to: Yup.date().min(Yup.ref('from'), `End date can't be before start date`),
})

export {
  editClientSchema,
  resetSchema,
  activateSchema,
  loginSchema,
  addUserSchema,
  kpiSchema,
  passwordSchema,
  createChallengeSchema,
}
