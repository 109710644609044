import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { OrganisationContext } from '../../context/OrganisationContext'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../ui-library/components/DataTable'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import LoadingAnim from '../../ui-library/components/loadingAnim/LoadingAnim'
import uuid from 'react-uuid'
import Typography from '../../ui-library/components/Typography'
import IconButton from '../../ui-library/components/IconButton'
import DashboardContainer from '../../components/shared/DashboardContainer'
import TabHeader from '../../ui-library/components/TabHeader'
import { getResults } from '@appt-digital/frontend-api-lib-wodity'
import moment from 'moment'
import Dropdown from '../../ui-library/components/Dropdown'
import { getAllChallenges } from '../../handlers/requests/challenges/challengeHandler'
import useDebounce from '../../hooks/useDebounce'
import { ImDownload3 } from 'react-icons/im'
import { useLocation } from 'react-router-dom'

function Results(props) {
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const debouncedSearch = useDebounce(search, 1000)
  const [challenge, setChallenge] = useState()
  const [challenges, setChallenges] = useState([])
  const [gender, setGender] = useState([])
  const [age, setAge] = useState([])
  const [type, setType] = useState([])
  const { state } = useLocation()

  useEffect(() => {
    const getAll = async () => {
      setLoading(true)
      const challenges = await getAllChallenges('')
      setChallenges(challenges?.data?.data)
      setChallenge({
        label: `${moment(challenges?.data?.data[0].start).format(
          'ddd MMM Do'
        )} - ${challenges?.data?.data[0].name}`,
        value: challenges?.data?.data[0],
      })
      if (state?.id) {
        const ourChallenge = challenges?.data?.data.find(
          (x) => x.id === state.id
        )
        setChallenge({
          label: `${moment(ourChallenge.start).format('ddd MMM Do')} - ${
            ourChallenge.name
          }`,
          value: ourChallenge,
        })
      }
    }
    getAll()
  }, [currentOrg])

  useEffect(() => {
    const getAll = async () => {
      if (debouncedSearch) {
        const results = await getResults(challenge?.value?.id, {
          gender: gender?.value,
          type: type?.value,
          name: search,
          dobFrom: age?.value?.from,
          dobTo: age?.value?.to,
        })
        setResults(results?.data)
        setLoading(false)
      } else {
        if (challenges.length > 0) {
          const results = await getResults(challenge?.value?.id, {
            gender: gender?.value,
            type: type?.value,
            name: search,
            dobFrom: age?.value?.from,
            dobTo: age?.value?.to,
          })
          setResults(results?.data)
          setLoading(false)
        }
      }
    }
    getAll()
  }, [currentOrg, challenge, gender, type, age, debouncedSearch])

  return (
    <DashboardContainer>
      <TabHeader
        title={'Results'}
        showSearch
        searchValue={search}
        onChangeSearch={(e) => setSearch(e.target.value)}
      >
        <Paper
          style={{
            ...theme?.dashboard?.container,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <Dropdown
              title={'Challenge'}
              type="challenge"
              id={'challenge'}
              name={'challenge'}
              placeholder={'Filter By Challenge'}
              setSelected={(e) => {
                setChallenge(e)
              }}
              selected={challenge}
              items={challenges?.map((challenge) => {
                return {
                  label: `${moment(challenge?.start).format('ddd MMM Do')} - ${
                    challenge.name
                  }`,
                  value: challenge,
                }
              })}
              style={{ container: { width: '30%', alignSelf: 'flex-end' } }}
            />
            <div
              style={{
                display: 'flex',
                lexDirection: 'row',
                width: '10%',
                gap: 20,
              }}
            >
              <Dropdown
                title={'Gender'}
                type="gender"
                id={'gender'}
                name={'gender'}
                placeholder={'Gender'}
                setSelected={(e) => {
                  setGender(e)
                }}
                selected={gender}
                items={[
                  { label: 'All', value: 'All' },
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                style={{ width: 300, alignSelf: 'flex-end' }}
              />
              <Dropdown
                title={'Type'}
                type="type"
                id={'type'}
                name={'type'}
                placeholder={'Type'}
                setSelected={(e) => {
                  setType(e)
                }}
                selected={type}
                items={[
                  { label: 'All', value: 'All' },
                  { label: 'Rx', value: 'Rx' },
                  { label: 'Scaled', value: 'Scaled' },
                ]}
                style={{ width: 300, alignSelf: 'flex-end' }}
              />
              <Dropdown
                title={'Age'}
                type="age"
                id={'age'}
                name={'age'}
                placeholder={'Age'}
                setSelected={(e) => {
                  setAge(e)
                }}
                selected={age}
                items={[
                  { label: 'All', value: 'All' },
                  {
                    label: '18-34',
                    value: {
                      from: moment().subtract(34, 'years').toISOString(),
                      to: moment().subtract(18, 'years').toISOString(),
                    },
                  },
                  {
                    label: '35-39',
                    value: {
                      from: moment().subtract(39, 'years').toISOString(),
                      to: moment().subtract(35, 'years').toISOString(),
                    },
                  },
                  {
                    label: '40-44',
                    value: {
                      from: moment().subtract(44, 'years').toISOString(),
                      to: moment().subtract(40, 'years').toISOString(),
                    },
                  },
                  {
                    label: '45-49',
                    value: {
                      from: moment().subtract(49, 'years').toISOString(),
                      to: moment().subtract(45, 'years').toISOString(),
                    },
                  },
                  {
                    label: '50-54',
                    value: {
                      from: moment().subtract(54, 'years').toISOString(),
                      to: moment().subtract(50, 'years').toISOString(),
                    },
                  },
                  {
                    label: '55-59',
                    value: {
                      from: moment().subtract(59, 'years').toISOString(),
                      to: moment().subtract(55, 'years').toISOString(),
                    },
                  },
                  {
                    label: '60-64',
                    value: {
                      from: moment().subtract(64, 'years').toISOString(),
                      to: moment().subtract(60, 'years').toISOString(),
                    },
                  },
                  {
                    label: '65+',
                    value: {
                      from: moment().subtract(152, 'years').toISOString(),
                      to: moment().subtract(65, 'years').toISOString(),
                    },
                  },
                ]}
                style={{ width: 300, alignSelf: 'flex-end' }}
              />
            </div>
          </div>

          <>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  <LoadingAnim />
                </div>
              ) : results?.length > 0 ? (
                <TableContainer {...props} theme={theme}>
                  <Table {...props} theme={theme}>
                    <TableHead {...props} theme={theme}>
                      {[
                        'Best Effort',
                        'Gender',
                        'Type',
                        'Age',
                        'Name',
                        'Video',
                      ]?.map((header, index) => {
                        return (
                          <TableCell {...props} key={index} theme={theme}>
                            <Typography
                              style={
                                header === 'Video'
                                  ? {
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      marginRight: 20,
                                    }
                                  : {}
                              }
                            >
                              {header}
                            </Typography>
                          </TableCell>
                        )
                      })}
                    </TableHead>
                    {results?.map((result, index) => {
                      const today = moment() // Current date
                      const age = today.diff(result?.ownerModel?.dob, 'years')
                      return (
                        <TableRow theme={theme} {...props} key={uuid()}>
                          <TableCell theme={theme} {...props} align="left">
                            <Typography>
                              {result?.reps
                                ? result?.reps + ' Reps'
                                : result?.timeSeconds
                                ? result?.timeSeconds + ' Seconds'
                                : result?.weightKG + ' KG'}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>
                              {result?.ownerModel?.gender}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>
                              {result?.challengeModel?.recordType}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>{age}</Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <Typography>
                              {result?.ownerModel?.firstname + ' '}
                              {result?.ownerModel?.lastname}
                            </Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            {result?.evidence?.fileUrl && (
                              <IconButton
                                source={'tableIcon'}
                                style={{ float: 'right', marginRight: 15 }}
                                icon={ImDownload3}
                                onClick={() => {
                                  window.open(result?.evidence?.fileUrl)
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  source={'h1'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '70vh',
                  }}
                >
                  No Data
                </Typography>
              )}
            </Stack>
          </>
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default Results
