const chartColors = {
  a: '#FF5A28',
  c: '#4B4D52',
  d: '#0066CC',
  e: '#FFD700',
  f: '#8F00FF',
  g: '#00FF00',
  h: '#FF4500',
  i: '#663399',
  j: '#00CED1',
  k: '#FF69B4',
  l: '#556B2F',
  m: '#FF8C00',
}

export default chartColors
