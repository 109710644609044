const formData = {
  shared: {
    path: 'Home >',
  },
  tabs: [
    {
      key: 1,
      title: 'Upcoming Challenges',
      route: '/dashboard/upcoming-challenges',
    },
    {
      key: 2,
      title: 'Previous Challenges',
      route: '/dashboard/previous-challenges',
    },

    {
      key: 3,
      title: 'Results',
      route: '/dashboard/results',
    },
    {
      key: 4,
      title: 'System Users',
      route: '/dashboard/system',
    },
    {
      key: 5,
      title: 'Stats',
      route: '/dashboard/stats',
    },
    // {
    //   key: 5,
    //   title: 'Reports',
    //   route: '/dashboard/reports',
    // },
  ],
}
const getFormData = (key, value) =>
  formData.tabs.find((t) => t.key === key)[value]

export { formData, getFormData }
