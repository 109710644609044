import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'

// components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '../../ui-library/components/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import InputAdornment from '@mui/material/InputAdornment'
import style from '../../style/style'
import { activateSchema } from '../../config/validation'
import {
  handleTermsClick,
  registerHandler,
} from '../../handlers/requests/auth/registerHandler'
import { AlertContext } from '../../context/AlertContext'
import TextField from '../../ui-library/components/TextField'
import Logo from '../../ui-library/components/Logo'
import CheckboxList from '../../ui-library/components/CheckboxList'
import Link from '../../ui-library/components/Link'
import PasswordField from '../../ui-library/components/PasswordField'
import uiText from '../../resources/uiText'
import LoadingButton from '../../ui-library/components/LoadingButton'
import LoadingAnim from '../../ui-library/components/loadingAnim/LoadingAnim'

export default function Register() {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const [loading] = useState(false)
  const [signupData] = useState(uiText.register)

  return (
    <Grid item xs={12}>
      {loading ? (
        <LoadingAnim />
      ) : (
        <>
          <Formik
            validationSchema={activateSchema}
            initialValues={{
              username: '',
              password: '',
              passwordConfirm: '',
              terms: false,
            }}
            onSubmit={(values) => {
              registerHandler(values, navigate, setAlert)
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div style={style.auth.container}>
                  <div style={style.auth.layout}>
                    <Logo image={''} alt="" />
                    <Typography
                      source={'subtitle'}
                      style={{
                        marginBottom: 60,
                        textAlign: 'center',
                        lineHeight: 0.8,
                        fontSize: 40,
                      }}
                    >
                      Register
                    </Typography>
                    {signupData.description !== '' ? (
                      <Typography variant="caption">
                        {signupData.description}
                      </Typography>
                    ) : null}
                    <TextField
                      name="username"
                      placeholder={signupData.email_label}
                      value={values.username}
                      onChange={handleChange}
                      source={'default'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="TEXT NEEDED">
                              <IconButton icon={HelpIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.username && touched.username && errors.username
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.username &&
                        touched?.username &&
                        errors?.username}
                    </Card>
                    <PasswordField
                      type="password"
                      name="password"
                      placeholder={signupData.password_label}
                      value={values.password}
                      onChange={handleChange}
                      showStrength
                      source={'default'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Your password should be a least 8 characters, include a mixture of uppercase and lowercase letters, at least one number and at least one character in !@#$%^&*()-_+=">
                              <IconButton icon={HelpIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.password && touched.password && errors.password
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.password &&
                        touched?.password &&
                        errors?.password}
                    </Card>
                    <PasswordField
                      type="password"
                      name="passwordConfirm"
                      placeholder={'Confirm Password*'}
                      sx={style.cssTextField}
                      value={values.passwordConfirm}
                      onChange={handleChange}
                      source={'default'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="This should match the Password above">
                              <IconButton icon={HelpIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.passwordConfirm &&
                        touched.passwordConfirm &&
                        errors.passwordConfirm
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.passwordConfirm &&
                        touched?.passwordConfirm &&
                        errors?.passwordConfirm}
                    </Card>

                    <CheckboxList
                      onChange={handleChange}
                      name="terms"
                      value={values.terms}
                      color="secondary"
                      items={[
                        <Link
                          source={'link'}
                          key={1}
                          onClick={() => handleTermsClick()}
                        >
                          {signupData.terms_condition_label}
                        </Link>,
                      ]}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.terms && touched.terms && errors.terms
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.terms && touched?.terms && errors?.terms}
                    </Card>

                    <LoadingButton
                      // icon={IoIosArrowDropright}
                      // icontype={'endIcon'}
                      source={'authButton'}
                      type={'submit'}
                    >
                      Register
                    </LoadingButton>

                    <Link source={'link'} onClick={() => navigate('/login')}>
                      {signupData.link_label}
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  )
}
