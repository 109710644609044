import {
  addKPIUser,
  deleteUser,
  getUsers,
  inviteUser,
  resetPassword,
  updateUser,
} from '@appt-digital/frontend-api-lib-wodity'
import uiText from '../../../resources/uiText'

const editAppUser = async (id, data, setAlert) => {
  const values = {
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    mobile: data.values.mobile,
    ...(data.values.emailAddress && {
      email: data.values.emailAddress,
      username: data.values.emailAddress,
    }),
  }

  const req = await updateUser(id, values)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error updating user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User updated',
    action: false,
  })
}

const addKPIUserHandler = async (values, setAlert) => {
  const addUserData = {
    username: values.emailAddress,
    email: values.emailAddress,
    firstname: values.firstname,
    lastname: values.lastname,
    type: values.type,
  }
  const req = await addKPIUser(addUserData)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addAdminUserHandler = async (data, setAlert) => {
  const values = {
    username: data.values.emailAddress,
    email: data.values.emailAddress,
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    mobile: data.values.mobile,
    type: 'admin',
  }
  const req = await inviteUser(values)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addAppUserHandler = async (data, setAlert) => {
  const values = {
    username: data.values.emailAddress,
    email: data.values.emailAddress,
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    mobile: data.values.mobile,
  }
  const req = await inviteUser(values)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const getAppUsers = async (search = '', sort) => {
  const params =
    '{ "$or": [{"email": {"$contL": "' +
    search +
    '"}}, {"firstname": {"$contL": "' +
    search +
    '"}}, {"lastname": {"$contL": "' +
    search +
    '"}}], "type": "admin"}'

  return await getUsers(params, sort)
}

const deleteAppUser = async (id, setAlert) => {
  const req = await deleteUser(id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User deleted.',
    action: false,
  })
}
const resetAppUser = async (email, setAlert) => {
  const req = await resetPassword(email)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: uiText.errors.reset,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Password reset request sent',
  })
}
export {
  editAppUser,
  addKPIUserHandler,
  addAdminUserHandler,
  addAppUserHandler,
  getAppUsers,
  deleteAppUser,
  resetAppUser,
}
