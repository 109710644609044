import React, { useContext, useEffect, useState } from 'react'
import Typography from '../../../../ui-library/components/Typography'
import Dropdown from '../../../../ui-library/components/Dropdown'
import Button from '../../../../ui-library/components/Button'
import DashboardContainer from '../../../../components/shared/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import Paper from '@mui/material/Paper'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import { getAppUsers } from '../../../../handlers/requests/users/appUsersHandler'
import {
  deleteGDPRData,
  getGDPRData,
} from '../../../../handlers/requests/users/gdpr'
import ConfirmationModal from '../../../../components/dialogs/ConfirmationModal'
import { AlertContext } from '../../../../context/AlertContext'
import { FaTrash } from 'react-icons/fa'

const Gdpr = () => {
  const { theme } = useContext(ThemeContext)
  const [userData, setUserData] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedDeleteUser, setSelectedDeleteUser] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    const appUsers = async () => {
      const users = await getAppUsers('', 'firstname,ASC')
      setUserData(users?.data)
    }
    appUsers()
  }, [])
  return (
    <DashboardContainer>
      <TabHeader title="GDPR Centre" />
      <Paper style={theme?.dashboard?.container}>
        <Typography source={'h2'}>Download User Data</Typography>
        <Typography source={'paragraph'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          euismod arcu eu metus sodales pulvinar. Donec in maximus dui. Cras
          cursus laoreet magna in commodo. Vestibulum ac fermentum nibh. Sed
          tristique risus aliquam venenatis lacinia. Nam at augue nulla.
        </Typography>
        <Dropdown
          items={userData?.map((user) => ({
            label: user?.firstname + ' ' + user?.lastname,
            value: user?.id,
          }))}
          setSelected={setSelectedUser}
        />
        <Button
          disabled={selectedUser === ''}
          onClick={async () => {
            await getGDPRData(selectedUser?.value, selectedUser?.label)
          }}
        >
          Download All User Data
        </Button>
        <Typography source={'h2'}>Delete User Data</Typography>
        <Typography source={'paragraph'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          euismod arcu eu metus sodales pulvinar. Donec in maximus dui. Cras
          cursus laoreet magna in commodo. Vestibulum ac fermentum nibh. Sed
          tristique risus aliquam venenatis lacinia. Nam at augue nulla.
        </Typography>
        <Typography>All Data will be permanently deleted</Typography>
        <Dropdown
          items={userData?.map((user) => ({
            label: user?.firstname + ' ' + user?.lastname,
            value: user?.id,
          }))}
          setSelected={setSelectedDeleteUser}
        />
        <Button
          disabled={selectedDeleteUser === ''}
          onClick={() => {
            setConfirmDelete(true)
          }}
        >
          Delete All User Data
        </Button>
      </Paper>
      <ConfirmationModal
        onAccept={async () => {
          setConfirmDelete(false)
          if (selectedUser?.value) {
            await deleteGDPRData(selectedDeleteUser?.value, setAlert)
          } else {
            setAlert({
              open: true,
              severity: 'error',
              message: 'Please select a user',
              action: false,
            })
          }
        }}
        isOpen={confirmDelete}
        onRequestClose={() => setConfirmDelete(false)}
        type={'Delete'}
        text={
          'Are you sure you wish to permanently delete all of this users data?'
        }
        icon={FaTrash}
      />
    </DashboardContainer>
  )
}

export default Gdpr
