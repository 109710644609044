import React, { useContext, useEffect } from 'react'
import chartColors from '../styles/chartColors'
import uuid from 'react-uuid'
import Typography from './Typography'
import { ThemeContext } from './ThemeContext'
import ToolTip from './ToolTip'
import ErrorBoundary from '../../utils/ErrorBoundary'

const ChartWrapper = (props) => {
  const {
    Chart,
    data = '',
    keys,
    title,
    subHeading,
    source,
    style,
    toolTip,
    labels = '',
  } = props
  const { theme } = useContext(ThemeContext)

  useEffect(() => {}, [])
  const styles = {
    container: {
      ...theme?.chartWrapper?.container,
      ...theme?.chartWrapper?.[source]?.container,
      ...style?.container,
      border: '1px',
      borderColor: '#ffffff',
      borderRadius: 15,
      boxShadow: '0px 0px 6px #00000029',
      padding: 10,
      marginTop: '20px',
    },
    key: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    chart: {
      ...theme?.chartWrapper?.chart,
      ...theme?.chartWrapper?.[source]?.chart,
      ...style?.chart,
    },
    color: {
      width: 15,
      height: 15,
      margin: 5,
    },
  }

  if (data?.length < 1 || labels?.length < 1) return <></>
  return (
    <ErrorBoundary>
      <div style={styles.container}>
        <Typography source={'h4'}>{title}</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography source={'h5'}>{subHeading}</Typography>
          {toolTip ? <ToolTip /> : <></>}
        </div>
        <div style={styles.chart}>
          <Chart {...props} colors={Object.values(chartColors)} data={data} />
        </div>
        {keys ? (
          keys?.map((k, i) => {
            return (
              <div key={uuid()} style={styles.key}>
                <div
                  style={{
                    ...styles.color,
                    backgroundColor: Object.values(chartColors)[i],
                  }}
                ></div>
                <Typography source={'list'}>{k}</Typography>
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default ChartWrapper
