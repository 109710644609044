import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from '../../ui-library/components/Button'
import StyledIcon from '../../ui-library/components/StyledIcon'
import color from '../../ui-library/styles/color'

function ConfirmationModal({
  isOpen,
  onAccept,
  onRequestClose,
  text,
  type,
  icon,
}) {
  return (
    <div>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>
          <StyledIcon
            Icon={icon}
            style={{
              icon: { color: color.primary, marginBottom: -3, paddingRight: 5 },
            }}
          />
          {type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ color: 'black' }}>{text}</Typography>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={onAccept}>Yes</Button>
          <Button
            source={'altButton'}
            style={{
              icon: {
                color: '#FF5A28',
              },
              '&:hover': {
                color: 'white',
                backgroundColor: '#4B4D52',
              },
            }}
            onClick={onRequestClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationModal
