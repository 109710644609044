import React from 'react'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import chartColors from '../styles/chartColors'

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart = ({ datasets, labels }) => {
  const colors = Object.values(chartColors)
  const data = {
    labels,
    datasets: [
      {
        label: 'Count',
        data: datasets,
        backgroundColor: [...colors],
      },
    ],
  }
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div style={{ width: 200 }}>
      <Doughnut data={data} options={options} />
    </div>
  )
}
export default DoughnutChart
