import React, { useContext } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { createChallengeSchema } from '../../../config/validation'
import TextField from '../../../ui-library/components/TextField'
import Button from '../../../ui-library/components/Button'
import Link from '../../../ui-library/components/Link'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../components/shared/DashboardContainer'
import TabHeader from '../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from '../../../ui-library/components/Dropdown'
import DateSelect from '../../../ui-library/components/DateSelect'
import { createChallenge } from '@appt-digital/frontend-api-lib-wodity'
import Toggle from 'react-toggle'
import './switch.css'
import * as moment from 'moment'

function CreateChallenge() {
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: '',
      start: new Date(),
      end: new Date(),
      recordType: '',
      moreInfo: '',
      prize: '',
      rulesMaleRX: '',
      rulesMaleScaled: '',
      rulesFemaleRX: '',
      rulesFemaleScaled: '',
      published: false,
      instructionalVideoUrl: '',
      description: '',
      isWod: true,
    },
    validationSchema: createChallengeSchema,
    onSubmit: () => handleCreate(),
  })
  const handleCreate = async () => {
    await createChallenge(values)
    navigate(state?.prevRoute || '/dashboard/upcoming-challenges')
  }
  return (
    <>
      <DashboardContainer>
        <TabHeader title={state?.title}>
          <Paper style={theme?.dashboard?.container}>
            <div style={{ width: '60%' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate(
                      state?.prevRoute || '/dashboard/upcoming-challenges'
                    )
                  }}
                  style={{ color: 'black' }}
                >
                  {`< Back`}
                </Link>
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography source={'h2'}>Challenge Details</Typography>

                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography style={{ fontSize: 13, marginRight: 5 }}>
                        Published
                      </Typography>
                      <Toggle
                        defaultChecked={values.published}
                        onChange={(e) =>
                          setFieldValue('published', e.target.checked)
                        }
                      />
                    </label>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <TextField
                      type="name"
                      name="name"
                      title="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'name'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <DateSelect
                      type="start"
                      name="start"
                      title={'From'}
                      date={values.start}
                      setDate={(e) => {
                        setFieldValue(
                          'start',
                          moment(e).startOf('day').toISOString()
                        )
                      }}
                    />
                    <DateSelect
                      type="end"
                      name="end"
                      title={'To'}
                      date={values.end}
                      setDate={(e) => {
                        setFieldValue(
                          'end',
                          moment(e).endOf('day').toISOString()
                        )
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Dropdown
                      type="reordType"
                      name="recordType"
                      label={'Record Type'}
                      items={[
                        { label: 'Weight', value: 'weight' },
                        { label: 'Rep Count', value: 'reps' },
                        { label: 'Duration', value: 'time' },
                      ]}
                      value={values.recordType}
                      setSelected={(e) => setFieldValue('recordType', e.value)}
                    />
                    <TextField
                      title={'More Info Link'}
                      type="moreInfo"
                      name="moreInfo"
                      placeholder="More Info Link"
                      value={values.moreInfo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`moreInfo`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <TextField
                    title={'Instructional Video Link'}
                    type="instructionalVideoUrl"
                    name="instructionalVideoUrl"
                    placeholder="Instructional Video Link"
                    value={values.instructionalVideoUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`instructionalVideoUrl`}
                    errors={errors}
                    touched={touched}
                    required
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Prize
                  </Typography>
                  <TextField
                    type="prize"
                    name="prize"
                    placeholder="Prize"
                    value={values.prize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`prize`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Rules - Male
                  </Typography>
                  <TextField
                    type="rulesMaleRX"
                    name="rulesMaleRX"
                    placeholder="Rx"
                    value={values.rulesMaleRX}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesMaleRX`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 10 }}
                  />
                  <TextField
                    type="rulesMaleScaled"
                    name="rulesMaleScaled"
                    placeholder="Scaled"
                    value={values.rulesMaleScaled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesMaleScaled`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Rules - Female
                  </Typography>
                  <TextField
                    type="rulesFemaleRX"
                    name="rulesFemaleRX"
                    placeholder="Rx"
                    value={values.rulesFemaleRX}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesFemaleRX`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 10 }}
                  />
                  <TextField
                    type="rulesFemaleScaled"
                    name="rulesFemaleScaled"
                    placeholder="Scaled"
                    value={values.rulesFemaleScaled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesFemaleScaled`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />

                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Description
                  </Typography>
                  <TextField
                    type="description"
                    name="description"
                    placeholder="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`description`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Button type="submit">Save</Button>
                </form>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default CreateChallenge
