import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar } from '@mui/material'
import Typography from '../../ui-library/components/Typography'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import { formData } from '../../resources/formData'
import style from '../../style/style'
import { logout } from '@appt-digital/frontend-api-lib-wodity'
import { UserContext } from '../../context/UserContext'
import localStorageService from '../../utils/localStorageService'
import { OrganisationContext } from '../../context/OrganisationContext'
import Link from '../../ui-library/components/Link'
import Logo from '../../ui-library/components/Logo'
import { ThemeContext } from '../../ui-library/components/ThemeContext'
import NavButton from '../../ui-library/components/NavButton'
import toCapitalised from '../../utils/capitaliseString'

export default function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useContext(UserContext)
  const { organisations, setOrganisations, setCurrentOrg } =
    useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const [open, setState] = useState(false)
  useEffect(() => {
    setOrganisations(user?.organisations)
    setCurrentOrg(organisations?.[0])
  }, [user, organisations])

  const toggleDrawer = (openDrawer, index) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    // changes the function state according to the value of open
    setState(openDrawer)
  }

  const logoutHandler = async () => {
    localStorageService.clear()
    navigate('/login')
    await logout()
  }
  return (
    <>
      <Box
        style={theme?.sidebar?.background}
        sx={{
          display: {
            md: 'block',
            lg: 'block',
            xl: 'block',
            xs: 'none',
            sm: 'none',
          },
        }}
      >
        <div style={theme?.sidebar?.header}>
          <Logo
            source={'sideBar'}
            image={''}
            alt=""
            onClick={() => navigate('/dashboard')}
          />
        </div>
        <div style={{ padding: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            {/* <Typography */}
            {/*  source={'sideBarHeader'} */}
            {/*  style={{ paddingLeft: 10, paddingRight: 5 }} */}
            {/* >{`${ */}
            {/*  location.pathname.split('/')[ */}
            {/*    location.pathname.split('/').length - 1 */}
            {/*  ] === 'gdpr' */}
            {/*    ? 'GDPR' */}
            {/*    : toCapitalised( */}
            {/*        location.pathname.split('/')[ */}
            {/*          location.pathname.split('/').length - 1 */}
            {/*        ] */}
            {/*      ) */}
            {/* } |  `}</Typography> */}
            <Typography style={{ fontSize: 13, marginLeft: 10 }}>
              {'WODity Portal |'}
            </Typography>
            <Typography source={'sideBarHeaderBold'}>
              {(user?.firstname !== null || user?.firstname !== undefined) &&
              (user?.lastname !== null || user?.lastname !== undefined)
                ? `${toCapitalised(user?.firstname)} ${toCapitalised(
                    user?.lastname
                  )}`
                : ''}
            </Typography>
          </div>
          {/* {user?.organisations?.length > 1 && ( */}
          {/*  <Dropdown */}
          {/*    type="org" */}
          {/*    id={'org'} */}
          {/*    name={'org'} */}
          {/*    setSelected={(e) => { */}
          {/*      selectOrg(e.target.value) */}
          {/*    }} */}
          {/*    selected={currentOrg?.name} */}
          {/*    items={organisations?.map((org) => ({ */}
          {/*      label: org?.name, */}
          {/*      value: org, */}
          {/*    }))} */}
          {/*    inputLabel="Client" */}
          {/*    Icon={ArrowDropDownIcon} */}
          {/*  /> */}
          {/* )} */}
          <List>
            {formData.tabs.map((tab) => {
              return (
                <ListItem disablePadding key={tab.key}>
                  <NavButton
                    destination={tab.route}
                    params={tab}
                    style={{ marginTop: 10 }}
                    source={
                      tab.route
                        .split('/')
                        .includes(location.pathname.split('/')[2])
                        ? 'selected'
                        : 'sidebar'
                    }
                  >
                    {tab.title}
                  </NavButton>
                </ListItem>
              )
            })}
          </List>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50%',
            marginBottom: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              marginLeft: 15,
            }}
          >
            <Link
              style={{ fontSize: 15 }}
              source={'logout'}
              onClick={logoutHandler}
            >
              Logout
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              // flexDirection: 'column',
              marginLeft: 15,
              marginTop: 5,
            }}
          >
            <Typography source="h6" style={{ fontSize: 10 }}>
              {/*  Remove ?? when variables exist */}
              {process.env.REACT_APP_VERSION ?? 'v1.2.2'}
              {process.env.REACT_APP_SHA ?? 'fa105b2'}
            </Typography>
          </div>
        </div>
      </Box>

      {/* mobile menu */}
      <AppBar
        style={style.appBar}
        sx={{ display: { md: 'none', lg: 'none', xl: 'none' } }}
      >
        <Logo
          image={''}
          alt=""
          onClick={() => navigate('/dashboard')}
          style={{
            width: '40%',
            marginTop: 20,
            padding: 10,
            borderRadius: 15,
          }}
        />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            mr: 2,
            display: {
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
          }}
          icon={MenuIcon}
        />

        {/* The outside of the drawer */}
        <Drawer
          // from which side the drawer slides in
          anchor="right"
          // if open is true --> drawer is shown
          open={open}
        >
          {/* The inside of the drawer */}
          <Box
            sx={{
              padding: '10px 0',
              height: 1,
              backgroundColor: 'white',
              width: '100vw',
            }}
          >
            <IconButton icon={CloseIcon} onClick={toggleDrawer(false)} />

            <Box sx={{ mb: 2 }}>
              {formData.tabs.map((tab) => {
                return (
                  <ListItem disablePadding key={tab.key}>
                    <NavButton
                      destination={tab.route}
                      params={tab}
                      source={
                        tab.route
                          .split('/')
                          .includes(location.pathname.split('/')[2])
                          ? 'selected'
                          : 'sidebar'
                      }
                    >
                      <Typography>{tab.title}</Typography>
                    </NavButton>
                  </ListItem>
                )
              })}
            </Box>
            <div style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
              {/* {user?.organisations?.length > 1 && ( */}
              {/*  <Dropdown */}
              {/*    type="org" */}
              {/*    id={'org'} */}
              {/*    name={'org'} */}
              {/*    setSelected={(e) => { */}
              {/*      selectOrg(e.target.value) */}
              {/*    }} */}
              {/*    selected={currentOrg?.name} */}
              {/*    items={organisations?.map((org) => ({ */}
              {/*      label: org?.name, */}
              {/*      value: org, */}
              {/*    }))} */}
              {/*    inputLabel="Client" */}
              {/*    Icon={ArrowDropDownIcon} */}
              {/*  /> */}
              {/* )} */}
              <Link source={'logout'} onClick={logoutHandler}>
                Logout
              </Link>
              <Typography source="h6">
                {/*  Remove ?? when variables exist */}
                {process.env.REACT_APP_VERSION ?? 'v1.2.2'}
                {process.env.REACT_APP_SHA ?? 'fa105b2'}
              </Typography>
            </div>
          </Box>
        </Drawer>
      </AppBar>
    </>
  )
}
