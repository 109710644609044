import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../../components/shared/DashboardContainer'
import TabHeader from '../../../ui-library/components/TabHeader'
import Paper from '@mui/material/Paper'
import { getKPIS } from '@appt-digital/frontend-api-lib-wodity'
import ChartWrapper from '../../../ui-library/components/ChartWrapper'
import BarChart from '../../../ui-library/components/BarChart'
import PieChart from '../../../ui-library/components/PieChart'
import DoughnutChart from '../../../ui-library/components/DoughnutChart'
import Dropdown from '../../../ui-library/components/Dropdown'
import DateSelect from '../../../ui-library/components/DateSelect'
import LocalStorageService from '../../../utils/localStorageService'

const Statistics = () => {
  const date = new Date()
  const [chartData, setChartData] = useState([])
  const [dateType, setDateType] = useState({
    label: 'This Month',
    value: 'MONTH',
  })
  const [dateFrom, setDateFrom] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [dateTo, setDateTo] = useState(new Date())

  const [customDateFrom, setCustomDateFrom] = useState(
    LocalStorageService.get('customDateFrom') || new Date()
  )
  const [customDateTo, setCustomDateTo] = useState(
    LocalStorageService.get('customDateTo') || new Date()
  )

  const handleDateSelected = () => {
    const today = new Date()
    // WEEK
    const startOfWeek = new Date(today)
    const dayOfWeek = today.getDay()
    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // adjust when day is Sunday
    startOfWeek.setDate(diff)
    const endOfWeek = new Date(startOfWeek)
    endOfWeek.setDate(endOfWeek.getDate() + 6)
    // WEEK
    // MONTH
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    // MONTH
    // YEAR
    const startOfYear = new Date(today.getFullYear(), 0, 1)
    const endOfYear = new Date(today.getFullYear(), 11, 31)
    // YEAR
    // LAST MONTH
    const lastMonthYear =
      today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear()
    const lastMonthMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1
    const startOfLastMonth = new Date(lastMonthYear, lastMonthMonth, 1)
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
    // LAST MONTH
    // THIS QUARTER
    const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3
    const startOfQuarter = new Date(today.getFullYear(), quarterStartMonth, 1)
    const endOfQuarter = new Date(
      startOfQuarter.getFullYear(),
      startOfQuarter.getMonth() + 3,
      0
    )
    // THIS QUARTER
    // LAST QUARTER
    const lastQuarterStartMonth = Math.floor((today.getMonth() - 3) / 3) * 3
    const startOfLastQuarter = new Date(
      today.getFullYear(),
      lastQuarterStartMonth,
      1
    )
    const endOfLastQuarter = new Date(
      startOfLastQuarter.getFullYear(),
      startOfLastQuarter.getMonth() + 3,
      0
    )
    // LAST QUARTER
    // LAST YEAR
    const lastYearStartYear = today.getFullYear() - 1
    const startOfLastYear = new Date(lastYearStartYear, 0, 1)
    const endOfLastYear = new Date(today.getFullYear(), 0, 0)
    // LAST YEAR
    switch (dateType.value) {
      case 'WEEK':
        setDateFrom(startOfWeek)
        setDateTo(endOfWeek)
        break
      case 'MONTH':
        setDateFrom(startOfMonth)
        setDateTo(endOfMonth)
        break
      case 'YEAR':
        setDateFrom(startOfYear)
        setDateTo(endOfYear)
        break
      case 'LAST_YEAR':
        setDateFrom(startOfLastYear)
        setDateTo(endOfLastYear)
        break
      case 'LAST_MONTH':
        setDateFrom(startOfLastMonth)
        setDateTo(endOfLastMonth)
        break
      case 'THIS_QUARTER':
        setDateFrom(startOfQuarter)
        setDateTo(endOfQuarter)
        break
      case 'LAST_QUARTER':
        setDateFrom(startOfLastQuarter)
        setDateTo(endOfLastQuarter)
        break
      case 'CUSTOM':
        setDateFrom(customDateFrom)
        setDateTo(customDateTo)
        break
      // default:
    }
  }

  useEffect(() => {
    handleDateSelected()
  }, [dateType])

  useEffect(() => {
    const getData = async () => {
      const params = {
        startDate: dateFrom,
        endDate: dateTo,
      }
      const res = await getKPIS(params)
      setChartData(res.data)
    }
    getData()
  }, [dateFrom, dateTo])

  return (
    <DashboardContainer>
      <TabHeader title={'Stats'}>
        <Paper
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              width: '85%',
              padding: 20,
              borderRadius: 20,
            }}
          >
            <Dropdown
              type="dateType"
              id={'dateType'}
              name={'dateType'}
              title={'Select Period'}
              setSelected={(selected) => {
                setDateType(selected)
              }}
              selected={dateType}
              items={[
                { label: 'This Week', value: 'WEEK' },
                { label: 'This Month', value: 'MONTH' },
                { label: 'Last Month', value: 'LAST_MONTH' },
                { label: 'This Year', value: 'YEAR' },
                { label: 'Last Year', value: 'LAST_YEAR' },
                { label: 'This Quarter', value: 'THIS_QUARTER' },
                { label: 'Last Quarter', value: 'LAST_QUARTER' },
                { label: 'Custom', value: 'CUSTOM' },
              ]}
              placeholder={'Select period...'}
            />
            <DateSelect
              date={dateFrom}
              setDate={(value) => {
                setDateType({ label: 'Custom', value: 'CUSTOM' })
                setCustomDateFrom(value)
                LocalStorageService.set('customDateFrom', value)
                setDateFrom(value)
              }}
              title={'From'}
            />
            <DateSelect
              date={dateTo}
              setDate={(value) => {
                setDateType({ label: 'Custom', value: 'CUSTOM' })
                setCustomDateTo(value)
                LocalStorageService.set('customDateTo', value)
                setDateTo(value)
              }}
              title={'To'}
            />
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gridGap: '30px',
              width: '100%',
            }}
          >
            <ChartWrapper
              toolTip={''}
              title={'Users'}
              subHeading={'Users by gender'}
              Chart={BarChart}
              data={[chartData?.female, chartData?.male]}
              visible={true}
              labels={['Female', 'Male']}
              axis={'x'}
              scalesRemove={
                {
                  // y: {
                  //   grid: {
                  //     display: false,
                  //   },
                  // },
                }
              }
            />
            <ChartWrapper
              toolTip={''}
              title={''}
              subHeading={'Users by account type'}
              Chart={BarChart}
              data={[chartData?.freeAccount, chartData?.paidAccount]}
              visible={true}
              labels={['Free Account', 'Paid Account']}
              axis={'x'}
            />
            <ChartWrapper
              toolTip={''}
              title={''}
              subHeading={'Users by RX'}
              Chart={BarChart}
              data={[chartData?.rx, chartData?.scaled]}
              visible={true}
              labels={['RX', 'Scaled']}
              axis={'x'}
            />
            <ChartWrapper
              toolTip={''}
              title={''}
              subHeading={'Users by City'}
              Chart={PieChart}
              data={chartData?.city?.map((item) => {
                return item?.city_count
              })}
              visible={true}
              labels={chartData?.city?.map((item) => {
                return item?.city
              })}
              style={{
                chart: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
            <ChartWrapper
              toolTip={''}
              title={''}
              subHeading={'Users by Country'}
              Chart={DoughnutChart}
              data={[1]}
              datasets={chartData?.country?.map((item) => {
                return item?.country_count
              })}
              visible={true}
              labels={chartData?.country?.map((item) => {
                return item?.country
              })}
              style={{
                chart: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
          </div>
          <ChartWrapper
            toolTip={'Users Entered WOD'}
            title={''}
            subHeading={'Users Entered WOD'}
            Chart={BarChart}
            data={chartData?.userChallenges?.map((item) => {
              return item?.count
            })}
            visible={true}
            labels={chartData?.userChallenges?.map((item) => {
              return item?.name + '-' + item?.start
            })}
            axis={'y'}
            style={{ container: { width: '100%' } }}
          />
        </Paper>
      </TabHeader>
    </DashboardContainer>
  )
}

export default Statistics
