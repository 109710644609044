import MUIIconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import StyledIcon from './StyledIcon'

const CssButton = styled(MUIIconButton)(({ theme, source, style }) => {
  return { ...theme?.iconButton, ...theme?.iconButton?.[source], ...style }
})

const IconButton = (props) => {
  const { icon, source, style, children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssButton {...props} theme={theme}>
      {children || (
        <StyledIcon
          style={{
            ...theme?.iconButton?.icon,
            ...theme?.iconButton?.[source]?.icon,
            ...style,
          }}
          Icon={icon}
          {...props}
        />
      )}
    </CssButton>
  )
}

MUIIconButton.defaultProps = {}
export default IconButton
