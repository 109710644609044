import React, { useContext } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { createChallengeSchema } from '../../../config/validation'
import TextField from '../../../ui-library/components/TextField'
import Button from '../../../ui-library/components/Button'
import Link from '../../../ui-library/components/Link'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../components/shared/DashboardContainer'
import TabHeader from '../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import DateSelect from '../../../ui-library/components/DateSelect'
import Dropdown from '../../../ui-library/components/Dropdown'
import { editChallenge } from '@appt-digital/frontend-api-lib-wodity'
import Toggle from 'react-toggle'
import { AiOutlineUnorderedList } from 'react-icons/ai'

function EditChallenge() {
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const initialEdit = {
    name: state?.challenge?.name || '',
    start: state.challenge?.start || new Date(),
    end: state.challenge?.end || new Date(),
    recordType: state.challenge?.recordType || 'eee',
    moreInfo: state.challenge?.moreInfo || '',
    prize: state.challenge?.prize || '',
    rulesMaleRX: state.challenge?.rulesMaleRX || '',
    rulesMaleScaled: state.challenge?.rulesMaleScaled || '',
    rulesFemaleRX: state.challenge?.rulesFemaleRX || '',
    rulesFemaleScaled: state.challenge?.rulesFemaleScaled || '',
    published: state.challenge?.published || false,
    instructionalVideoUrl: state.challenge?.instructionalVideoUrl || '',
    description: state.challenge?.description || '',
  }
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialEdit,
    validationSchema: createChallengeSchema,
    onSubmit: () => handleEdit(),
  })
  const handleEdit = async () => {
    await editChallenge(values, state.challenge?.id)
    navigate(state?.prevRoute || '/dashboard/upcoming-challenges')
  }

  return (
    <>
      <DashboardContainer>
        <TabHeader title={state?.title}>
          <Paper style={theme?.dashboard?.container}>
            <div style={{ width: '60%' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate(
                      state?.prevRoute || '/dashboard/upcoming-challenges'
                    )
                  }}
                  style={{ color: 'black' }}
                >
                  {`< Back`}
                </Link>
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography source={'h2'}>Challenge Details</Typography>

                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography style={{ fontSize: 13, marginRight: 5 }}>
                        Published
                      </Typography>
                      <Toggle
                        defaultChecked={values.published}
                        onChange={(e) =>
                          setFieldValue('published', e.target.checked)
                        }
                      />
                    </label>

                    <Button
                      icon={AiOutlineUnorderedList}
                      icontype={'endIcon'}
                      onClick={() => {
                        navigate('/dashboard/results', {
                          state: { id: state.challenge?.id },
                        })
                      }}
                    >
                      View Entries
                    </Button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <TextField
                      type="name"
                      name="name"
                      title="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'name'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <DateSelect
                      type="start"
                      name="start"
                      title={'From'}
                      date={values.start}
                      setDate={(e) => setFieldValue('start', e)}
                    />
                    <DateSelect
                      type="end"
                      name="end"
                      title={'To'}
                      date={values.end}
                      setDate={(e) => setFieldValue('end', e)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Dropdown
                      title={'Record Type'}
                      label={'Record Type'}
                      items={[
                        { label: 'Weight', value: 'weight' },
                        { label: 'Rep Count', value: 'reps' },
                        { label: 'Duration', value: 'time' },
                      ]}
                      selected={{
                        label: values?.recordType,
                        value: values?.recordType,
                      }}
                      setSelected={(e) => setFieldValue('recordType', e.value)}
                    />
                    <TextField
                      title={'More Info Link'}
                      type="moreInfo"
                      name="moreInfo"
                      placeholder="More Info Link"
                      value={values.moreInfo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`moreInfo`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <TextField
                    title={'Instructional Video Link'}
                    type="instructionalVideoUrl"
                    name="instructionalVideoUrl"
                    placeholder="Instructional Video Link"
                    value={values.instructionalVideoUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`instructionalVideoUrl`}
                    errors={errors}
                    touched={touched}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Prize
                  </Typography>
                  <TextField
                    type="prize"
                    name="prize"
                    placeholder="Prize"
                    value={values.prize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`prize`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Rules - Male
                  </Typography>
                  <TextField
                    title={'Rx'}
                    type="rulesMaleRX"
                    name="rulesMaleRX"
                    placeholder="Rx"
                    value={values.rulesMaleRX}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesMaleRX`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 10 }}
                  />
                  <TextField
                    title={'Scaled'}
                    type="rulesMaleScaled"
                    name="rulesMaleScaled"
                    placeholder="Scaled"
                    value={values.rulesMaleScaled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesMaleScaled`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Rules - Female
                  </Typography>
                  <TextField
                    title={'Rx'}
                    type="rulesFemaleRX"
                    name="rulesFemaleRX"
                    placeholder="Rx"
                    value={values.rulesFemaleRX}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesFemaleRX`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 10 }}
                  />
                  <TextField
                    title={'Scaled'}
                    type="rulesFemaleScaled"
                    name="rulesFemaleScaled"
                    placeholder="Scaled"
                    value={values.rulesFemaleScaled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`rulesFemaleScaled`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
                    Description
                  </Typography>
                  <TextField
                    type="description"
                    name="description"
                    placeholder="Description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`description`}
                    errors={errors}
                    touched={touched}
                    multiline
                    style={{ height: 'auto', marginBottom: 20 }}
                  />
                  <Button type="submit">Save</Button>
                </form>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default EditChallenge
